import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Story from "../components/story"

export const data = graphql`
  query($slug: String!) {
    stories: allSanityPost(
      filter: { category: { slug: { current: { eq: $slug } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt(formatString: "MM/DD/YYYY")
        category {
          title
          slug {
            current
          }
        }
        people
        designation
        excerpt
      }
    }
    sanityCategory(slug: { current: { eq: $slug } }) {
      title
      description
    }
  }
`

export default function CategoryStories({ data: { stories, sanityCategory } }) {
  return (
    <Layout>
      <div className="my-8 mb-16 px-4 sm:px-8">
        <div>
          <span className="font-medium text-sm uppercase">Stories</span>
          <h2 className="text-4xl xl:text-5xl section-head text-red-700">
            {sanityCategory.title}
          </h2>
        </div>
        <h4>{sanityCategory.description}</h4>
      </div>
      <div className="mt-4 px-4 sm:px-8">
        {stories.nodes.map(story => (
          <Story
            title={story.title}
            slug={story.slug.current}
            mainImage={story.mainImage}
            excerpt={story.excerpt}
            date={story.publishedAt}
            category={story.category}
            people={story.people}
            designation={story.designation}
            key={story.slug.current}
            disableLink={true}
          />
        ))}
      </div>
      <Link
        className="mx-4 sm:mx-8 mt-16  inline-block  hover:underline "
        to="/stories"
      >
        &larr; View all Stories
      </Link>
    </Layout>
  )
}
